import React from 'react';
import MktSection from 'src/interface/sections/mkt-section';
import { BtnLink, NextLink } from 'src/interface/basics/links';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { SectionHeader, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const JobSection = () => {
  return (
    <MktSection className='py-8'>
    	<Container>
	      <SectionHeader>
	      	<SectionTitle>No postings</SectionTitle>
	      	<SectionText>Please check back later...</SectionText>
	      </SectionHeader>
      </Container>
    </MktSection>
  )
}

export default JobSection;
