import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allGraphCmsPortfolio {
          nodes {
            id
            photo {
              url
            }
            title
            subtitle
            description
            position
            slug
          }
        }
      }
    `
  )
  return data.allGraphCmsPortfolio.nodes;
}

export default useSiteMetadata;
