import React, { useEffect } from 'react';

import JobHeader from 'src/components/jobs/job-header';
import JobSection from 'src/components/jobs/job-section';

import useSiteMetadata from 'src/hooks/work-hook';

import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer';

const JobsPage = () => {
  const jobs = useSiteMetadata();
  const formattedJobs = jobs.sort((a, b) => a.position - b.position);


  // Hooks
  useEffect(() => {
    console.log('data: ', formattedJobs);
  }, [])

  return (
    <MktRoute title='Mile Hi Labs | Jobs' description="We're a team of designers, builders, and futurists that embrace change and are looking to make a dent in the world.">
      <MktNav />
      <JobHeader />
      <main className='relative mvh-50'>
        <JobSection />
        <MktPrefooter />
      </main>
      <MktFooter />
    </MktRoute>
  )
}

export default JobsPage;
