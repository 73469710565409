import React, { Fragment, useEffect, useState } from 'react';
import AnimeWrapper from 'src/interface/vendors/anime-wrapper';
import { Triangle, Circle, Square } from 'src/assets';
import { timeout } from 'src/utils/helpers';

const ShapesAnimation = () => {

	// Return
	return (
		<div className='z-10'>
			<Fragment>
				<div className='absolute top-0 left-75'>
          <AnimeWrapper
          	loop={true}
            delay={300}
            duration={6000}
            scale={[4, 5]}
            opacity={[0, .1]}
            rotate={[180, 180]}
            translateX={['0rem', '4rem']}
            translateY={['0rem', '4rem']}
            easing='easeOutSine'
            direction='alternate'
            elasticity={1}>
            <Triangle />
          </AnimeWrapper>
        </div>
          <div className='absolute top-0 right-75'>
          <AnimeWrapper
          	loop={true}
            delay={300}
            duration={6000}
            scale={[4, 3]}
            opacity={[0, .1]}
            rotate={[180, 180]}
            translateX={['0rem', '4rem']}
            translateY={['0rem', '4rem']}
            easing='easeOutSine'
            direction='alternate'
            elasticity={1}>
            <Triangle />
          </AnimeWrapper>
        </div>

        <div className='absolute bottom-0 left-75'>
          <AnimeWrapper
          	loop={true}
            delay={300}
            duration={6000}
            scale={[4, 3]}
            opacity={[0, .1]}
            rotate={[0, 0]}
            translateX={['0rem', '4rem']}
            translateY={['0rem', '4rem']}
            easing='easeOutSine'
            direction='alternate'
            elasticity={1}>
            <Triangle />
          </AnimeWrapper>
        </div>

        <div className='absolute bottom-0 right-75'>
          <AnimeWrapper
          	loop={true}
            delay={300}
            duration={6000}
            scale={[4, 5]}
            opacity={[0, .1]}
            rotate={[0, 0]}
            translateX={['0rem', '4rem']}
            translateY={['0rem', '4rem']}
            easing='easeOutSine'
            direction='alternate'
            elasticity={1}>
            <Triangle />
          </AnimeWrapper>
        </div>
			</Fragment>
		</div>
	)
}

export default ShapesAnimation;
