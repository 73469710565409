import React, { createRef, useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import { Section } from 'src/interface/basics/sections';

const MktSection = ({ id, className = '', children }) => {
	const [ animation, setAnimation ] = useState('');
	const ref = createRef(null);

	return (
		<Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
			<Section id={id} ref={ref} className={`mkt-section-block ${animation} ${className}`}>
				{children}
			</Section>
		</Waypoint>
	)
}

export default MktSection;
